import Vue from "vue"

import {
    ACCOUNT,
    BALANCE_UPDATE
} from '@/store/mutations.type'
import {
    LOGOUT
} from '@/store/actions.type'

const state = {
    accounts: {},
}

const getters = {
    savingsAccounts() {
        return state.accounts
    },
}

const actions = {

}

const mutations = {
    [ACCOUNT](context, account) {
        if (account.product !== 'savings') {
            return
        }

        Vue.set(state.accounts, account.id, account)
    },

    [BALANCE_UPDATE](context, balance) {
        if (balance.product !== 'savings' || state.accounts[balance.account_id] === undefined) {
            return
        }

        state.accounts[balance.account_id].balance += balance.balanceChange
        state.accounts[balance.account_id].credit += balance.creditChange
    },

    [LOGOUT]() {
        Vue.set(state, 'accounts',  {})
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
