import io from 'socket.io-client'

import {COMPANY_NAME, FAVICON, SOCKET_ENDPOINT} from "@/common/config"

import {APP_INIT, VARIABLES_LISTENER} from '@/store/actions.type'
import {
    SOCKET_CONNECTED,
    SYMBOL,
    BALANCE_UPDATE,
    LOGOUT,
    QUOTES,
    TOKEN,
    TRANSACTION,
    SUPPORT_MESSAGE,
    TROLLBOX_MESSAGE,
    QUOTE,
    SOCKET_STATE, CFD_ORDER, DATA_REFRESH,
    TIME_SHIFT,
    USER_PERSONAL,
    SET_ACCOUNT, SHOW_WEB_NOTIFICATION
} from '@/store/mutations.type'
import {parseQuote} from '@/common/helpers'

let quotesPool = {}
let quotesInterval = null

const state = {
    socket: null,
    socketState: false,
    socketQuotesSubs: {},
    token: null,
    lastUpdate: 0,
    dataRefreshTimeout: null
}

const getters = {
    socketState() {
        return state.socketState
    }
}

const actions = {
    [APP_INIT]() {
        state.socket = io(SOCKET_ENDPOINT, {
            transports: ['websocket']
        })

        state.socket.on('connect', () => {
            this.commit(SOCKET_CONNECTED)
        })

        state.socket.on('reconnect', () => {
            this.commit(SOCKET_CONNECTED)
        })

        state.socket.on('disconnect', () => {
            this.commit(SOCKET_STATE, false)
        })

        state.socket.on('error', () => {
            this.commit(SOCKET_STATE, false)
        })
    }
}

const mutations = {
    [SOCKET_CONNECTED]() {
        state.socketState = true

        if (quotesInterval === null) {
            quotesInterval = setInterval(() => {
                this.commit(QUOTES, quotesPool)

                quotesPool = {}
            }, 1000)
        }

        state.socket.removeAllListeners('q').on('q', quote => {
            quote.forEach(q => {
                q = parseQuote(q)

                if (document.hidden) {
                    this.commit(QUOTE, q)
                } else {
                    quotesPool[q.symbol] = q
                }
            })
        })

        state.socket.removeAllListeners('order_open').on('order_open', order => {
            if (order._ts && order._ts > state.lastUpdate) {
                state.lastUpdate = order._ts
            }

            this.commit(CFD_ORDER, order)
        })

        state.socket.removeAllListeners('order_close').on('order_close', order => {
            if (order._ts && order._ts > state.lastUpdate) {
                state.lastUpdate = order._ts
            }

            this.commit(CFD_ORDER, order)
        })

        state.socket.removeAllListeners('order_update').on('order_update', order => {
            if (order._ts && order._ts > state.lastUpdate) {
                state.lastUpdate = order._ts
            }

            order.isUpdate = true
            this.commit(CFD_ORDER, order)
        })

        state.socket.removeAllListeners('order_activate').on('order_activate', order => {
            if (order._ts && order._ts > state.lastUpdate) {
                state.lastUpdate = order._ts
            }

            this.commit(CFD_ORDER, order)
        })

        state.socket.removeAllListeners('balance_update').on('balance_update', balance => {
            if (balance._ts && balance._ts > state.lastUpdate) {
                state.lastUpdate = balance._ts
            }

            this.commit(BALANCE_UPDATE, balance)
        })

        state.socket.removeAllListeners('transaction').on('transaction', transaction => {
            if (transaction._ts && transaction._ts > state.lastUpdate) {
                state.lastUpdate = transaction._ts
            }

            this.commit(TRANSACTION, transaction)
        })

        state.socket.removeAllListeners('chat_message').on('chat_message', message => {
            this.commit(SUPPORT_MESSAGE, message)
            this.commit(SHOW_WEB_NOTIFICATION, {
                title: `New message from ${COMPANY_NAME}`,
                body: message.text,
                icon: FAVICON,
                vibrate: [200, 100, 200, 100, 200, 100, 200],
                requireInteraction: true,
                tag:`${message.id}`
            })
        })

        state.socket.off('trollbox_message').on('trollbox_message', message => {
            this.commit(TROLLBOX_MESSAGE, message)
        })

        state.socket.off('logout').on('logout', () => {
            this.dispatch(LOGOUT)
        })

        state.socket.off('time').on('time', time => {
            this.commit(TIME_SHIFT, time-Date.now())
        })

        state.socket.removeAllListeners('last_update').on('last_update', time => {
            if (!state.lastUpdate) {
                state.lastUpdate = time
            }

            if (time > state.lastUpdate) {
                if (state.dataRefreshTimeout) {
                    clearTimeout(state.dataRefreshTimeout)
                }

                state.dataRefreshTimeout = setTimeout(() => {
                    state.dataRefreshTimeout = null

                    this.commit(DATA_REFRESH)
                }, 500)
            }
        })

        state.socket.off('user').on('user', user => {
            this.commit(USER_PERSONAL, user)
        })

        state.socket.emit('last_update')

        state.socket.on('cfd_variables', variable => {
            this.commit(VARIABLES_LISTENER, variable)
        })

        for(let n in this.getters.symbols) {
            state.socket.emit('quotes.sub', {
                symbol: this.getters.symbols[n].symbol,
                group: this.getters.symbols[n].group
            })
        }

        state.socket.off('time').on('time', time => {
            this.commit(TIME_SHIFT, time-Date.now())
        })

        state.socket.off('cfd_account').on('cfd_account', account => {
            this.commit(SET_ACCOUNT, account)
        })
    },

    [TOKEN](context, token) {
        state.token = token
        state.socketState = false

        state.socket.destroy()

        state.socket = io(SOCKET_ENDPOINT, {
            transports: ['websocket'],
            query: {
                Authorization: token
            }
        })

        state.socket.on('connect', () => {
            this.commit(SOCKET_CONNECTED)
        })

        state.socket.on('reconnect', () => {
            this.commit(SOCKET_CONNECTED)
        })

        state.socket.on('disconnect', () => {
            this.commit(SOCKET_STATE, false)
        })

        state.socket.on('error', () => {
            this.commit(SOCKET_STATE, false)
        })
    },

    [SOCKET_STATE](context, val) {
        state.socketState = val
    },

    [LOGOUT]() {
        state.socketState = false

        state.socket.destroy()

        state.socket = io(SOCKET_ENDPOINT, {
            transports: ['websocket']
        })

        state.socket.on('connect', () => {
            this.commit(SOCKET_CONNECTED)
        })

        state.socket.on('reconnect', () => {
            this.commit(SOCKET_CONNECTED)
        })

        state.socket.on('disconnect', () => {
            this.commit(SOCKET_STATE, false)
        })

        state.socket.on('error', () => {
            this.commit(SOCKET_STATE, false)
        })
    },

    [SYMBOL](context, symbol) {
        if (state.socketQuotesSubs[symbol.symbol] === undefined) {
            state.socketQuotesSubs[symbol.symbol] = {
                symbol: symbol.symbol,
                group: symbol.group
            }

            if (state.socketState) {
                state.socket.removeAllListeners('q').on('q', quote => {
                    quote.forEach(q => {
                        q = parseQuote(q)

                        if (document.hidden) {
                            this.commit(QUOTE, q)
                        } else {
                            quotesPool[q.symbol] = q
                        }
                    })
                })

                state.socket.emit('quotes.sub', {
                    symbol: symbol.symbol,
                    group: symbol.group
                })
            }
        } else {
            if (state.socketState) {
                state.socket.emit('quotes.unsub', {
                    symbol: state.socketQuotesSubs[symbol.symbol].symbol,
                    group: state.socketQuotesSubs[symbol.symbol].group
                })

                state.socket.emit('quotes.sub', {
                    symbol: symbol.symbol,
                    group: symbol.group
                })

                state.socketQuotesSubs[symbol.symbol] = {
                    symbol: symbol.symbol,
                    group: symbol.group
                }
            }
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
