export const APP_INIT = 'appInit'
export const APP_READY = 'appReady'
export const PRE_LOGIN = 'preLogin'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const REGISTER = 'register'
export const PERSONAL_UPDATE = 'personalUpdate'
export const PASSWORD_CHANGE = 'passwordChange'
export const PASSWORD_RESTORE = 'passwordRestore'
export const PASSWORD_RESET = 'passwordReset'
export const DEPOSIT_REQUEST = 'depositRequest'
export const DEPOSIT_OTP = 'depositOtp'
export const DEPOSITS_GET = 'depositsGet'
export const WITHDRAWAL_REQUEST = 'withdrawalRequest'
export const WITHDRAWALS_GET = 'withdrawalsGet'
export const ATTACHMENT_UPLOAD = 'attachmentUpload'
export const DOCUMENT_UPLOAD = 'documentUpload'
export const DOCUMENTS_GET = 'documentsGet'
export const TRANSFER_REQUEST = 'transferRequest'
export const AVATAR_UPLOAD = 'avatarUpload'
export const DEMO_ACCOUNT_RESET = 'demoAccountReset'
export const CHART_DATA_REFRESH = 'chartDataRefresh'

export const BINARY_ORDER_OPEN_REQUEST = 'binaryOrderOpenRequest'

export const CFD_ORDER_OPEN_REQUEST = 'cfdOrderOpenRequest'
export const CFD_ORDER_CLOSE_REQUEST = 'cfdOrderCloseRequest'
export const CFD_ORDER_MODIFY_REQUEST = 'cfdOrderModifyRequest'

export const STAKING_MAKE = 'stakingMake'
export const STAKING_CLAIM = 'stakingClaim'

export const OTP_CREATE = 'otpCreate'
export const OTP_ACTIVATE = 'otpActivate'
export const OTP_DISABLE = 'otpDisable'

export const PLAY_CLICK = 'playClick'
export const PLAY_ORDER_OPEN = 'playOrderOpen'
export const PLAY_ORDER_WIN = 'playOrderWin'
export const PLAY_ORDER_LOSE = 'playOrderLose'

export const REFERRALS_PAYOUTS_GET = 'referralsPayoutsGet'
export const REFERRALS_REFERRALS_GET = 'referralsReferralsGet'

export const SUPPORT_SEND = 'supportSend'
export const SUPPORT_MESSAGES_GET = 'supportMessagesGet'
export const SUPPORT_READ = 'supportRead'

export const TROLLBOX_MESSAGE_SEND = 'trollboxMessageSend'

export const ANNOUNCEMENTS_GET = 'announcementsGet'
export const ANNOUNCEMENTS_VIEW = 'announcementsView'

export const REFRESH_USER = 'refreshUser'

export const DASHBOARD_DATA = 'dashboardData'
export const DASHBOARD_BALANCE = 'dashboardBalance'

export const GENERATE_INVOICE = 'generateInvoice'
export const DOWNLOAD_INVOICE = 'downloadInvoice'
export const UPLOAD_CONFIRMATION = 'uploadConfirmation'

export const CANCEL_WITHDRAWAL = 'cancelWithdrawal'
export const GET_HISTORY_ASSET = 'getHistoryAsset'
export const GTE_AUTOTRADER_CONFIG = 'getAutoTraderConfig'
export const SET_AUTOTRADER_CONFIG = 'setAutoTraderConfig'
export const GET_BANKS_LIST = 'getBanksList'
export const VARIABLES_LISTENER = 'variablesListener'
