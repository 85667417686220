import Vue from "vue"
import Vuex from "vuex"

import io from "./io.module"
import view from "./view.module"
import market from "./market.module"
import news from "./news.module"
import user from "./user.module"
import support from "./support.module"
import cfd from "./cfd.module"
import savings from "./savings.module"
import staking from "./staking.module"

let modules = {
    io,
    view,
    market,
    news,
    user,
    support,
    cfd,
    savings,
    staking
}

if (process.env.VUE_APP_MODULE_REFERRALS) {
    modules.referrals = require("./referrals.module").default
}

if (process.env.VUE_APP_MODULE_ANNOUNCEMENTS) {
    modules.announcements = require("./announcements.module").default
}

if (process.env.VUE_APP_MODULE_CHAT) {
    modules.chat = require("./chat.module").default
}

Vue.use(Vuex)

export default new Vuex.Store({
    modules
})