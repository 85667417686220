import Vue from 'vue'
import RestService from "@/common/rest.service";
import {REFERRALS_PAYOUTS, REFERRALS_REFERRALS} from "@/store/mutations.type";
import {REFERRALS_PAYOUTS_GET, REFERRALS_REFERRALS_GET} from "@/store/actions.type";

const state = {
    referralsPayouts: [],
    referralsReferrals: []
}

const getters = {
    referralsPayouts() {
        return state.referralsPayouts
    },

    referralsReferrals() {
        return state.referralsReferrals
    }
}

const actions = {
    [REFERRALS_PAYOUTS_GET]() {
        RestService.get('/referrals/payouts')
            .then(documents => {
                this.commit(REFERRALS_PAYOUTS, documents.rows)
            })
    },

    [REFERRALS_REFERRALS_GET]() {
        RestService.get('/referrals/referrals')
            .then(documents => {
                this.commit(REFERRALS_REFERRALS, documents.rows)
            })
    }
}

const mutations = {
    [REFERRALS_PAYOUTS](context, payouts) {
        payouts.reverse()
        Vue.set(state, 'referralsPayouts', payouts)
    },

    [REFERRALS_REFERRALS](context, referrals) {
        Vue.set(state, 'referralsReferrals', referrals)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}