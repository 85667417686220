import store from '@/store'

export const parseQuote = quote => {
    quote = quote.split(',')

    return {
        symbol: quote[0],
        group: quote[1],
        bid: parseFloat(quote[2]),
        ask: parseFloat(quote[3]),
        volume: parseFloat(quote[4]),
        trend: parseFloat(quote[5]),
        change: parseFloat(quote[6]),
        changePct: parseFloat(quote[7])
    }
}

export const currencySign = currency => {
    switch (currency) {
        case 'USD': return '$'
        case 'EUR': return '€'
        case 'GBP': return '£'
        case 'JPY': return '¥'
        case 'RUB': return '₽'
        case 'ILS': return '₪'
        case 'AUD': return '₳'
        case 'THB': return '฿'
        case 'KRW': return '₩'
        case 'BTC': return '₿'
        case 'INR': return '₹'
        case 'KZT': return '₸'
        case 'CZK': return 'Kč'
        default: return currency+' '
    }
}

export const orderTypeString = type => {
    switch (type) {
        case 0: return 'Buy'
        case 1: return 'Sell'
        case 2: return 'Buy Limit'
        case 3: return 'Sell Limit'
        case 4: return 'Buy Stop'
        case 5: return 'Sell Stop'
        default: return ''
    }
}

export const timeToString = time => {
    let stamp = time,
        date = new Date(stamp)

    return ('00' + date.getUTCDate()).slice(-2) + '.' +
        ('00' + (date.getUTCMonth()+1)).slice(-2) + '.' +
        date.getUTCFullYear() + ' ' +
        ('00' + date.getUTCHours()).slice(-2) + ':' +
        ('00' + date.getUTCMinutes()).slice(-2) + ':' +
        ('00' + date.getUTCSeconds()).slice(-2)
}

export const timeToPeriod = time => {
    if (time < 90) {
        return time+' sec'
    }

    if (time < 90 * 60) {
        return Math.floor(time / 60)+' min'
    }

    if (time < 3600 * 96) {
        return Math.floor(time / 3600)+' hr'
    }

    return Math.floor(time / 86400)+' days'
}

export const checkSession = (session, time) => {
    let date = time ? new Date(time) : new Date(),
        d = date.getUTCDay(),
        h = date.getUTCHours(),
        m = date.getUTCMinutes(),
        s = session[d][h],
        prev = h > 0 ? session[d][h-1] : (d > 0 ? session[d-1][23] : session[6][23])

    return (s === 0 || s === 1) ? !!s : (s * 60 < m ? !prev : !!prev)
}

export const requestFullScreen = (element) => {
    if (!document.fullscreenElement) {
        let requestMethod = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || element.msRequestFullScreen;

        if (requestMethod) {
            requestMethod.call(element);
        }
    } else {
        document.exitFullscreen()
    }
}

export const countriesList = () => {
    let obj = {
        AF: 'Afghanistan',
        AX: 'Aland Islands',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua And Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia',
        BA: 'Bosnia And Herzegovina',
        BW: 'Botswana',
        BV: 'Bouvet Island',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        CV: 'Cape Verde',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CD: 'Congo, Democratic Republic',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: 'Cote D\'Ivoire',
        HR: 'Croatia',
        CU: 'Cuba',
        CY: 'Cyprus',
        CZ: 'Czech Republic',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        ET: 'Ethiopia',
        FK: 'Falkland Islands (Malvinas)',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HM: 'Heard Island & Mcdonald Islands',
        VA: 'Holy See (Vatican City State)',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle Of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KR: 'Korea South',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Lao People\'s Democratic Republic',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libyan Arab Jamahiriya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MK: 'Macedonia',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia',
        MD: 'Moldova',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        AN: 'Netherlands Antilles',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestinian Territory, Occupied',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'Reunion',
        RO: 'Romania',
        RU: 'Russia',
        RW: 'Rwanda',
        BL: 'St Barthelemy',
        SH: 'St Helena',
        KN: 'St Kitts And Nevis',
        LC: 'St Lucia',
        MF: 'St Martin',
        PM: 'St Pierre And Miquelon',
        VC: 'St Vincent And Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome And Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia And Sandwich Isl.',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard And Jan Mayen',
        SZ: 'Swaziland',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan',
        TJ: 'Tajikistan',
        TZ: 'Tanzania',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad And Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks And Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States',
        UM: 'United States Outlying Islands',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela',
        VN: 'Viet Nam',
        VG: 'Virgin Islands, British',
        VI: 'Virgin Islands, U.S.',
        WF: 'Wallis And Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe'
    }

    return obj
}

export const countryToCode = name => {
    let obj = {
        AF: 'Afghanistan',
        AX: 'Aland Islands',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua And Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia',
        BA: 'Bosnia And Herzegovina',
        BW: 'Botswana',
        BV: 'Bouvet Island',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        BN: 'Brunei Darussalam',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        CV: 'Cape Verde',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo',
        CD: 'Congo, Democratic Republic',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: 'Cote D\'Ivoire',
        HR: 'Croatia',
        CU: 'Cuba',
        CY: 'Cyprus',
        CZ: 'Czech Republic',
        DK: 'Denmark',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        ET: 'Ethiopia',
        FK: 'Falkland Islands (Malvinas)',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HM: 'Heard Island & Mcdonald Islands',
        VA: 'Holy See (Vatican City State)',
        HN: 'Honduras',
        HK: 'Hong Kong',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle Of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        KR: 'Korea South',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Lao People\'s Democratic Republic',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libyan Arab Jamahiriya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao',
        MK: 'Macedonia',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia',
        MD: 'Moldova',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        AN: 'Netherlands Antilles',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestinian Territory, Occupied',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn',
        PL: 'Poland',
        PT: 'Portugal',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'Reunion',
        RO: 'Romania',
        RU: 'Russia',
        RW: 'Rwanda',
        BL: 'St Barthelemy',
        SH: 'St Helena',
        KN: 'St Kitts And Nevis',
        LC: 'St Lucia',
        MF: 'St Martin',
        PM: 'St Pierre And Miquelon',
        VC: 'St Vincent And Grenadines',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'Sao Tome And Principe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia And Sandwich Isl.',
        ES: 'Spain',
        LK: 'Sri Lanka',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard And Jan Mayen',
        SZ: 'Swaziland',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syrian Arab Republic',
        TW: 'Taiwan',
        TJ: 'Tajikistan',
        TZ: 'Tanzania',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad And Tobago',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks And Caicos Islands',
        TV: 'Tuvalu',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States',
        UM: 'United States Outlying Islands',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VE: 'Venezuela',
        VN: 'Viet Nam',
        VG: 'Virgin Islands, British',
        VI: 'Virgin Islands, U.S.',
        WF: 'Wallis And Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe'
    }

    for(let n in obj) {
        if (obj[n] === name) {
            return n
        }
    }

    return ''
}

export const isMobile = () => {
    // return true
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
}

export const randomId = () => {
    return 'r_'+Math.floor(Math.random()*999999)
}

let storage = {},
    lS

try {
    window.localStorage.getItem('test')
} catch (e) {
    lS = {
        setItem(key, val) {
            storage[key] = val
        },

        getItem(key) {
            return storage[key] || null
        },

        removeItem(key) {
            delete storage[key]
        }
    }
}

lS = lS || window.localStorage

export const localStorage = lS

export const dateCorr = () => {
    return new Date(Date.now() + store.getters.timeShift)
}

export const showPass = element => element && element.type ? element.type = "text" : ''
export const hidePass = element => element && element.type ? element.type = "password" : ''

export const countryToPhoneCode = name => {
    let obj = {
        AF: ["Afghanistan", "+39"],
        AX: ['Aland Islands', "+358"],
        AL: ['Albania', "+355"],
        DZ: ['Algeria', "+213"],
        AS: ['American Samoa', "+1684"],
        AD: ['Andorra', "+376"],
        AO: ['Angola', "+244"],
        AI: ['Anguilla', "+1264"],
        AQ: ['Antarctica', "+672"],
        AG: ['Antigua And Barbuda', "+1268"],
        AR: ['Argentina', "+54"],
        AM: ['Armenia', "+374"],
        AW: ['Aruba', "+297"],
        AU: ['Australia', "+61"],
        AT: ['Austria', "+43"],
        AZ: ['Azerbaijan', "+994"],
        BS: ['Bahamas', "+1242"],
        BH: ['Bahrain', "+973"],
        BD: ['Bangladesh', "+880"],
        BB: ['Barbados', "+1246"],
        BY: ['Belarus', "+375"],
        BE: ['Belgium', "+32"],
        BZ: ['Belize', "+501"],
        BJ: ['Benin', "+229"],
        BM: ['Bermuda', "+1441"],
        BT: ['Bhutan', "+975"],
        BO: ['Bolivia', "+591"],
        BA: ['Bosnia And Herzegovina', "+387"],
        BW: ['Botswana', "+1441"],
        BV: ['Bouvet Island', "+47"],
        BR: ['Brazil', "+55"],
        IO: ['British Indian Ocean Territory', "+246"],
        BN: ['Brunei Darussalam', "+673"],
        BG: ['Bulgaria', "+359"],
        BF: ['Burkina Faso', "+226"],
        BI: ['Burundi', "+257"],
        KH: ['Cambodia', "+855"],
        CM: ['Cameroon', "+237"],
        CA: ['Canada', "+1"],
        CV: ['Cape Verde', "+238"],
        KY: ['Cayman Islands', "+1345"],
        CF: ['Central African Republic', "+236"],
        TD: ['Chad', "+235"],
        CL: ['Chile', "+56"],
        CN: ['China', "+86"],
        CX: ['Christmas Island', "+61"],
        CC: ['Cocos (Keeling) Islands', "+61"],
        CO: ['Colombia', "+57"],
        KM: ['Comoros', "+569"],
        CG: ['Congo', "+242"],
        CD: ['Congo, Democratic Republic', "+243"],
        CK: ['Cook Islands', "+682"],
        CR: ['Costa Rica', "+506"],
        CI: ['Cote D\'Ivoire', "+225"],
        HR: ['Croatia', "+385"],
        CU: ['Cuba', "+53"],
        CY: ['Cyprus', "+357"],
        CZ: ['Czech Republic', "+420"],
        DK: ['Denmark', "+45"],
        DJ: ['Djibouti', "+253"],
        DM: ['Dominica', "+1767"],
        DO: ['Dominican Republic', "+1"],
        EC: ['Ecuador', "+593"],
        EG: ['Egypt', "+20"],
        SV: ['El Salvador', "+503"],
        GQ: ['Equatorial Guinea', "+240"],
        ER: ['Eritrea', "+291"],
        EE: ['Estonia', "+372"],
        ET: ['Ethiopia', "+251"],
        FK: ['Falkland Islands (Malvinas)', "+500"],
        FO: ['Faroe Islands', "+298"],
        FJ: ['Fiji', "+679"],
        FI: ['Finland', "+358"],
        FR: ['France', "+33"],
        GF: ['French Guiana', "+594"],
        PF: ['French Polynesia', "+689"],
        TF: ['French Southern Territories', "+262"],
        GA: ['Gabon', "+241"],
        GM: ['Gambia', "+220"],
        GE: ['Georgia', "+995"],
        DE: ['Germany', "+49"],
        GH: ['Ghana', "+233"],
        GI: ['Gibraltar', "+350"],
        GR: ['Greece', "+30"],
        GL: ['Greenland', "+299"],
        GD: ['Grenada', "+1473"],
        GP: ['Guadeloupe', "+590"],
        GU: ['Guam', "+1670"],
        GT: ['Guatemala', "+502"],
        GG: ['Guernsey', "+44"],
        GN: ['Guinea', "+224"],
        GW: ['Guinea-Bissau', "+245"],
        GY: ['Guyana', "+592"],
        HT: ['Haiti', "+509"],
        HM: ['Heard Island & Mcdonald Islands', "+672"],
        VA: ['Vatican City State', "+39"],
        HN: ['Honduras', "+504"],
        HK: ['Hong Kong', "+852"],
        HU: ['Hungary', "+36"],
        IS: ['Iceland', "+354"],
        IN: ['India', "+91"],
        ID: ['Indonesia', "+62"],
        IR: ['Iran', "+98"],
        IQ: ['Iraq', "+964"],
        IE: ['Ireland', "+353"],
        IM: ['Isle Of Man', "+44"],
        IL: ['Israel', "+972"],
        IT: ['Italy', "+39"],
        JM: ['Jamaica', "+1876"],
        JP: ['Japan', "+81"],
        JE: ['Jersey', "+44"],
        JO: ['Jordan', "+962"],
        KZ: ['Kazakhstan', "+7"],
        KE: ['Kenya', "+254"],
        KI: ['Kiribati', "+686"],
        KR: ['Korea South', "+82"],
        KP: ['Korea North', "+850"],
        KW: ['Kuwait', "+965"],
        KG: ['Kyrgyzstan', "+996"],
        LA: ['Lao People\'s Democratic Republic', "+856"],
        LV: ['Latvia', "+371"],
        LB: ['Lebanon', "+961"],
        LS: ['Lesotho', "+266"],
        LR: ['Liberia', "+231"],
        LY: ['Libyan Arab Jamahiriya', "+218"],
        LI: ['Liechtenstein', "+423"],
        LT: ['Lithuania', "+370"],
        LU: ['Luxembourg', "+352"],
        MO: ['Macao', "+853"],
        MK: ['Macedonia', "+389"],
        MG: ['Madagascar', "+261"],
        MW: ['Malawi', "+265"],
        MY: ['Malaysia', "+60"],
        MV: ['Maldives', "+960"],
        ML: ['Mali', "+223"],
        MT: ['Malta', "+356"],
        MH: ['Marshall Islands', "+692"],
        MQ: ['Martinique', "+596"],
        MR: ['Mauritania', "+222"],
        MU: ['Mauritius', "+230"],
        YT: ['Mayotte', "+262"],
        MX: ['Mexico', "+52"],
        FM: ['Micronesia', "+691"],
        MD: ['Moldova', "+373"],
        MC: ['Monaco', "+377"],
        MN: ['Mongolia', "+976"],
        ME: ['Republic of Montenegro', "+382"],
        MS: ['Montserrat', "+1664"],
        MA: ['Morocco', "+212"],
        MZ: ['Mozambique', "+258"],
        MM: ['Myanmar', "+95"],
        NA: ['Namibia', "+264"],
        NR: ['Nauru', "+674"],
        NP: ['Nepal', "+977"],
        NL: ['Netherlands', "+31"],
        AN: ['Netherlands Antilles', "+599"],
        NC: ['New Caledonia', "+687"],
        NZ: ['New Zealand', "+64"],
        NI: ['Nicaragua', "+505"],
        NE: ['Niger', "+227"],
        NG: ['Nigeria', "+234"],
        NU: ['Niue', "+683"],
        NF: ['Norfolk Island', "+672"],
        MP: ['Northern Mariana Islands', "+1670"],
        NO: ['Norway', "+47"],
        OM: ['Oman', "+968"],
        PK: ['Pakistan', "+92"],
        PW: ['Palau', "+680"],
        PS: ['Palestinian Territory, Occupied', "+970"],
        PA: ['Panama', "+507"],
        PG: ['Papua New Guinea', "+675"],
        PY: ['Paraguay', "+595"],
        PE: ['Peru', "+51"],
        PH: ['Philippines', "+63"],
        PN: ['Pitcairn', "+64"],
        PL: ['Poland', "+48"],
        PT: ['Portugal', "+351"],
        PR: ['Puerto Rico', "+1"],
        QA: ['Qatar', "+974"],
        RE: ['Reunion', "+262"],
        RO: ['Romania', "+40"],
        RU: ['Russia', "+7"],
        RW: ['Rwanda', "+250"],
        BL: ['St Barthelemy', "+590"],
        SH: ['St Helena', "+290"],
        KN: ['St Kitts And Nevis', "+1869"],
        LC: ['St Lucia', "+1758"],
        MF: ['St Martin', "+590"],
        PM: ['St Pierre And Miquelon', "+508"],
        VC: ['St Vincent And Grenadines', "+1784"],
        WS: ['Samoa', "+685"],
        SM: ['San Marino', "+378"],
        ST: ['Sao Tome And Principe', "+239"],
        SA: ['Saudi Arabia', "+966"],
        SN: ['Senegal', "+221"],
        RS: ['Serbia', "+381"],
        SC: ['Seychelles', "+248"],
        SL: ['Sierra Leone', "+232"],
        SG: ['Singapore', "+65"],
        SK: ['Slovakia', "+421"],
        SI: ['Slovenia', "+386"],
        SB: ['Solomon Islands', "+677"],
        SO: ['Somalia', "+252"],
        ZA: ['South Africa', "+27"],
        GS: ['South Georgia And Sandwich Isl.', "+500"],
        ES: ['Spain', "+34"],
        LK: ['Sri Lanka', "+94"],
        SD: ['Sudan', "+249"],
        SR: ['Suriname', "+597"],
        SJ: ['Svalbard And Jan Mayen', "+47"],
        SZ: ['Swaziland', "+268"],
        SE: ['Sweden', "+46"],
        CH: ['Switzerland', "+41"],
        SY: ['Syrian Arab Republic', "+963"],
        TW: ['Taiwan', "+886"],
        TJ: ['Tajikistan', "+992"],
        TZ: ['Tanzania', "+255"],
        TH: ['Thailand', "+66"],
        TL: ['Timor-Leste', "+670"],
        TG: ['Togo', "+228"],
        TK: ['Tokelau', "+690"],
        TO: ['Tonga', "+676"],
        TT: ['Trinidad And Tobago', "+1868"],
        TN: ['Tunisia', "+216"],
        TR: ['Turkey', "+90"],
        TM: ['Turkmenistan', "+993"],
        TC: ['Turks And Caicos Islands', "+1649"],
        TV: ['Tuvalu', "+688"],
        UG: ['Uganda', "+256"],
        UA: ['Ukraine', "+380"],
        AE: ['United Arab Emirates', "+971"],
        GB: ['United Kingdom', "+44"],
        US: ['United States', "+1"],
        UM: ['United States Outlying Islands', "+1"],
        UY: ['Uruguay', "+598"],
        UZ: ['Uzbekistan', "+998"],
        VU: ['Vanuatu', "+678"],
        VE: ['Venezuela', "+58"],
        VN: ['Viet Nam', "+84"],
        VG: ['Virgin Islands, British', "+1284"],
        VI: ['Virgin Islands, U.S.', "+1340"],
        WF: ['Wallis And Futuna', "+681"],
        EH: ['Western Sahara', "+212"],
        YE: ['Yemen', "+967"],
        ZM: ['Zambia', "+260"],
        ZW: ['Zimbabwe', "+263"],
        BQ: ['Bonaire', "+599"],
        WK: ['Wake Island', "+1808"],
        ZR: ['Zaire', "+243"],
        IC: ['Canary Islands', "+922"],
    }

    for(let n in obj) {
        if (obj[n][0] === name) {
            obj[n][2] = n
            return obj[n]
        }
    }

    return ''
}
