import Vue from 'vue'
import {ANNOUNCEMENTS_GET, ANNOUNCEMENTS_VIEW} from "@/store/actions.type"
import RestService from "@/common/rest.service";
import {ANNOUNCEMENT} from "@/store/mutations.type";

const state = {
    announcements: []
}

const getters = {
    announcements() {
        return state.announcements
    }
}

const actions = {
    [ANNOUNCEMENTS_GET]() {
        RestService.get('/announcements')
            .then(announcements => {
                for(let n in announcements) {
                    this.commit(ANNOUNCEMENT, announcements[n])
                }
            })
    },

    [ANNOUNCEMENTS_VIEW]() {
        RestService.post('/announcements/view', {
            limit: 5
        })
    }
}

const mutations = {
    [ANNOUNCEMENT](context, announcement) {
        for(let n in state.announcements) {
            if (state.announcements[n].id === announcement.id) {
                Vue.set(state.announcements, n, announcement)
                return
            }
        }
        state.announcements.push(announcement)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}