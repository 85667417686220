import Vue from 'vue'
import {APP_INIT} from '@/store/actions.type'
import {NEWS} from '@/store/mutations.type'
import RestService from '@/common/rest.service'
import {NEWS_CATEGORIES} from "@/common/config"

const state = {
    news: {},
    feed: []
}

const getters = {
    news() {
        return state.news
    },

    feed() {
        return state.feed
    }
}

const actions = {
    [APP_INIT]() {
        let options = ''
        if (NEWS_CATEGORIES !== 'all') {
            // later we can traverse the array here, but for now it's just:
            options = `?category=${NEWS_CATEGORIES[0]}`
        }
        RestService.get('/news'+options)
            .then(news => {
                this.commit(NEWS, news)
            })
    }
}

const mutations = {
    [NEWS](context, news) {
        for(let n in news) {
            Vue.set(state.news, news[n].id, news[n])
        }

        let feed = []

        for(let n in state.news) {
            feed.push(state.news[n])
        }

        feed.sort((a, b) => {
            return b.time - a.time
        })

        Vue.set(state, 'feed', feed)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}